<template>
<div class="main">
  <Tree service="/sys/sysDictType" :params="params" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        type: 1,
        parentId: '-1'
      },
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            field: "name",
            title: "字典名称",
            minWidth: 200,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "code",
            title: "字典标识",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "typeCode",
            title: "所属类型",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "orderCode",
            title: "排序",
            width: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 3,
              }
            },
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            field: "updateTime",
            title: "更新日期",
            width: 200,
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "typeId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              defaultParams: {
                type: 1,
                parentId: '-1'
              },
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["typeCode"] = node.code;
                },
              }
            },
          },
          {
            field: "name",
            title: "字典名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入字典名称",
              },
            },
          },
          {
            field: "code",
            title: "字典标识",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入字典标识",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 3,
                placeholder: "请输入排序号",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          typeId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入字典名称" },
          ],
          code: [
            { required: true, message: "请输入字典标识" },
          ],
          orderCode: [{ required: true, message: "请输入排序号" }],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          typeId: "",
          name: "",
          code: "",
          orderCode: "0",
          enabled: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "字典名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入字典名称",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          name: "",
          enabled: '',
        },
      },
    };
  },

  methods: {
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "typeId");
      this.formOptions.data['typeId'] = node.id;
      this.formOptions.data['typeCode'] = node.code;
      this.$refs.xGrid.searchOptions.data["typeId"] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
